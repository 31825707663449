$spacer: 1rem !default;

//
// Brand Colours (mostly used for copy).
//

// Rutgers colors
$rutgers-brand-primary-color: #f0c1d9;
//

$brand-primary-color: #005ca3;
$brand-primary-color-dark: #1a1a1d;
$brand-secondary-color: #94cee5;
$brand-tertiary-color: #007ab8;
$brand-high-contrast-color: white;
$brand-high-contrast-link-color: #add9eb;

$highlight-color-1: #cbf8fe;
$highlight-color-2: #ffe8ac;
$highlight-color-3: #eebad4;
$highlight-color-4: #9894eb;

$color-success: #7ad157;

$copy-primary-color: #1a1a1d;

$background-primary-color: #ebf0fa;
$background-secondary-color: #fff;
$background-tertiary-color: #d0dff6;

$border-color: #d8d8d8;

$border-radius: 0.5rem;

$footerHeight: 30px;
$mobileNavHeight: 82px;

$body-copy-size: 14px;

$form-input-color: $copy-primary-color; // #6399c5;

$heightHeader: 60px;
$heightBodyTopPadding: 3rem;
$heightProgressBar: 80px;
$heightFooter: 120px;

$mediaHeight: 900px;

$default-width-sm: 600px;

$buttonTransition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
