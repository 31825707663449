.App.unauthencated {
  background: $brand-primary-color;
  position: relative;
  height: 100%;

  &.start-page {
    background: white;
  }
}
.unauth-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.unauth-header .row {
  position: relative;
  z-index: 4;
}
.unauth-header-logo {
  margin-left: calc(3rem + 15px);
  padding-top: 25px;
  width: 190px;
  img {
    width: 100%;
  }
  @media (max-width: 1199.98px) {
    margin-left: 15px;
  }
  @media (max-width: 991.98px) {
    max-width: 180px;
    img {
      width: 100%;
    }
  }
  @media (max-width: 767.98px) {
    margin-left: 0;
  }
  a:focus {
    @include bootstrap-focus-shadow(white);
    border-radius: 0;
  }
}
.unauth {
  background: $brand-primary-color;
  color: $brand-high-contrast-color;
  h1 {
    font-weight: normal;
    margin-bottom: $spacer;
    color: $brand-high-contrast-color;
    font-size: 32px;
  }
  a {
    color: $brand-primary-color;
    text-decoration: underline;
  }
  .form-group {
    margin-bottom: $spacer * 0.5;
  }
  .unauth-welcome {
    display: none;
  }

  .unauth-character-left-desktop {
    display: none;

    @media (min-width: 992px) {
      display: block;
      position: absolute;
      overflow: hidden;
      left: -240px;
      top: 80px;
      height: 635px;
      width: 480px;
      svg {
        width: 480px;
        height: 564px;
      }
    }
    @media (min-width: 1200px) {
      left: -250px;
      top: 150px;
      height: 660px;
      width: 560px;
      svg {
        width: 560px;
        height: 658px;
      }
    }
  }
  .unauth-character-right-desktop {
    display: none;
    @media (min-width: 992px) {
      display: block;
      position: absolute;
      overflow: hidden;
      right: -260px;
      top: -100px;
      height: 635px;
      width: 560px;
      svg {
        width: 560px;
        height: 587px;
      }
    }
    @media (min-width: 1200px) {
      right: -260px;
      top: -60px;
      height: 635px;
      width: 600px;
      svg {
        width: 600px;
        height: 600px;
      }
    }
  }
  .unauth-character-right-mobile {
    display: block;
    position: absolute;
    overflow: hidden;
    right: -400px;
    top: -100px;
    height: 635px;
    width: 600px;
    svg {
      width: 600px;
      height: 600px;
    }
    @media (max-width: 575.98px) {
      display: none;
    }
    @media (min-width: 992px) {
      display: none;
    }
  }

  .unauth-header-wrapper {
    &.landing-wrapper {
      background: $brand-primary-color;
      position: relative;
      z-index: 4;
    }
  }

  .mobile-welcome {
    &.unauth-header-wrapper {
      .unauth-welcome {
        display: block;
        top: 20px;
        transform: none;
        position: relative;
        z-index: 3;
        margin: 0 auto;
        max-width: 600px;
        width: calc(100% + #{$spacer});
        // width: 100%;
        @media (min-width: 768px) {
          width: calc(60% + #{$spacer});
        }

        .unauth-title-description {
          margin-bottom: 1rem;
        }
        .unauth-description {
          color: $brand-high-contrast-color;
          border-left: 2px solid $brand-secondary-color;
          padding-left: $spacer;
          // margin-top: 2rem;
        }

        h1 {
          color: $brand-high-contrast-color;
          font-size: 59px;
          line-height: 48px;
          @media (max-width: 991.98px) {
            font-size: 48px;
            line-height: 42px;
          }
        }
        h2 {
          color: $brand-high-contrast-color;
        }
      }
      @media (max-width: 575.98px) {
        .unauth-welcome {
          margin: 0;
        }
      }
      @media (max-width: 991.98px) {
        .unauth-description {
          display: none;
        }

        .unauth-title-description {
          display: none;
        }
      }
    }
    &.unauth-component-wrapper {
      margin-top: 50px;
    }
  }

  .unauth-component-wrapper {
    color: $brand-high-contrast-color;
    position: relative;
    z-index: 3;
    margin: 0 auto;
    margin-top: $spacer * 3;
    max-width: $default-width-sm;
    width: calc(100% - #{$spacer} - #{$spacer});

    &.landing-wrapper {
      width: 100%;
      max-width: 100%;
    }

    @media (min-width: 768px) {
      width: 60%;
      @media only screen and (max-height: $mediaHeight) {
        margin-top: $spacer;
      }
    }
  }
}
.referral-content {
  background-color: $rutgers-brand-primary-color;
  //Left People |  DNA
  background-image: url("../../assets/images/People_Left.svg"),
    url("../../assets/images/DNA-cropped.svg");
  background-repeat: no-repeat;
  background-position:
    6% 40%,
    85% 20%;
  background-size: 35%, contain;

  @media (max-width: 1199.98px) {
    background-image: url("../../assets/images/People_Left.svg"),
      url("../../assets/images/DNA-cropped.svg");
    background-repeat: no-repeat;
    background-position:
      -18% 5%,
      10% 22%;
    background-size: 55%, cover;
  }

  @media (max-width: 991.98px) {
    background-image: url("../../assets/images/People_Left.svg"),
      url("../../assets/images/DNA-cropped.svg");
    background-repeat: no-repeat;
    background-position:
      -18% 5%,
      35% 22%;
    background-size: 55%, cover;
  }

  @media (max-width: 767.98px) {
    background-image: url("../../assets/images/People_Left.svg"),
      url("../../assets/images/DNA-cropped.svg");
    background-repeat: no-repeat;
    background-position:
      -18% 5%,
      40% 22%;
    background-size: 55%, 250%;
  }

  @media (max-width: 575.98px) {
    background: none;
  }
}

.unauth-web-page {
  background: white;
  color: $brand-high-contrast-color;
  h1 {
    font-weight: normal;
    margin-bottom: $spacer;
    color: $brand-high-contrast-color;
    font-size: 32px;
  }
  a {
    color: $brand-primary-color;
    text-decoration: underline;
  }
  .form-group {
    margin-bottom: $spacer * 0.5;
  }
  .unauth-welcome {
    display: none;
  }

  .unauth-web-page-header-wrapper {
    &.landing-wrapper {
      background: $brand-primary-color;
      position: relative;
      z-index: 4;
      min-height: 50px;
    }
  }

  .unauth-web-page-component-wrapper {
    color: $brand-high-contrast-color;
    position: relative;
    z-index: 3;
    margin: 0 auto;
    margin-top: $spacer * 3;
    max-width: $default-width-sm;
    width: calc(100% - #{$spacer} - #{$spacer});

    &.landing-wrapper {
      width: 100%;
      max-width: 100%;
      position: static;
      // z-index: unset;

      @media (max-width: 767.98px) {
        position: relative;
      }
    }

    @media (min-width: 768px) {
      width: 60%;
      @media only screen and (max-height: $mediaHeight) {
        margin-top: $spacer;
      }
    }
  }
}

.signup-body-wrapper,
.unauth-body-wrapper {
  background: white;
  color: $copy-primary-color;
  padding: $spacer * 1.5;
  border-radius: $border-radius;
  position: relative;
  height: auto;
  @media (min-width: 768px) {
    padding: $spacer * 2 $spacer * 3;
    position: relative;
    // padding-bottom: 120px !important;
  }
  a {
    color: $brand-tertiary-color;
  }
  h1 {
    color: $brand-primary-color;
  }
  .signup-body-buttons {
    display: inline-flex;
    text-align: right;
  }
}

.inlineText {
  white-space: nowrap;
}

.unauth-body-footer,
.password-submit-wrapper,
.next-text-media {
  text-align: center;
}

.workflow .next-text-media {
  position: static !important;
  width: 100% !important;
}

.signup-body-wrapper {
  @media only screen and (max-height: $mediaHeight) {
    min-height: 0;
    padding-bottom: 2rem;
  }
}

.confirm-email-admin .signup-body-wrapper {
  @media (min-width: 768px) {
    min-height: calc(
      100vh -
        (
          #{$heightBodyTopPadding} + #{$heightHeader} + #{$heightFooter} + #{$heightProgressBar +
            170px}
        )
    );
  }
}

.unauth-body-wrapper {
  min-height: auto;
  padding-bottom: 2rem !important;
  .password-requirements {
    @media (max-width: 575.98px) {
      padding-top: 10px;
    }
  }
}

.unauth-footer {
  background: $brand-primary-color;
  margin: 0;
  padding: 0;
  padding-top: $spacer * 3;
  padding-bottom: 13px;
  color: $brand-high-contrast-color;
  position: relative;
  bottom: 0;

  &.landing-wrapper a {
    color: $brand-primary-color;
  }
  img {
    height: 33px;
    object-fit: scale-down;
  }
  @media (max-width: 767.98px) {
    height: 75px;
    padding-top: $spacer * 2;
    font-size: 10px;
    img {
      height: 26px;
    }
  }

  a:focus {
    @include bootstrap-focus-shadow(white);
  }
}

.unauth-gradient {
  &:after {
    content: "";
    display: block;
    background: transparent;
    background: radial-gradient(
      ellipse,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(0, 92, 163, 0) 60%
    );
    position: absolute;
    top: 0;
    right: 100px;
    width: 600px;
    height: 700px;
    border-radius: 100%;
    @media (max-width: 1199.98px) {
      right: 0;
    }
  }
  @media (max-width: 991.98px) {
    display: none;
  }
}
