@import "../../styles/base/_variables.scss";

$color-title-text: $brand-primary-color;
$color-subtitle-text: #000;

.modalResponsive {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
}

.headerImage {
  margin: 0 auto;
}

.title {
  color: $color-title-text;
  font-weight: 700;
  font-size: 36px;
  text-wrap: balance;
}

.subtitle {
  color: $color-subtitle-text;
  text-wrap: pretty;
  font-size: 20px;
  font-weight: 700;
}

.description {
  text-wrap: balance;
}

.btnRecoveryBanner {
  width: 100% !important;
  padding: 12px 24px 12px 24px !important;
  @media (min-width: 576px) {
    padding: 12px 12px 12px 12px !important;
    width: 50% !important;
  }
}

.btnDismiss {
  text-decoration: underline !important;
}
