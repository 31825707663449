.container {
  display: flex;
  margin-top: -134px;
  @media (min-width: 768px) {
    margin-top: -169px;
  }
  svg {
    width: 265px;
    @media (min-width: 768px) {
      width: 395px;
    }
    height: auto;
  }
}

.headerImage {
  margin: 0 auto;
}

.modalTitle {
  font-size: 30px;
}
