.mobile-only {
  @media (min-width: 576px) {
    display: none !important;
  }
}

.desktop-only {
  @media (max-width: 767.98px) {
    display: none !important;
  }
}

.tablet-mobile-only {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.lg-tablet-mobile-only {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.xl-tablet-mobile-only {
  @media (min-width: 1200px) {
    display: none !important;
  }
}
