@import "../../styles/base/_variables.scss";

.referralLink {
  height: 44px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
  line-height: 21px;
  padding: 12px 18px;
  overflow: hidden;
  white-space: nowrap;
}

.withBorder {
  border: 1px solid $brand-primary-color;
}

.copyIcon {
  width: 20px;
  height: 20px;
}

.referralLinkFallback {
  border-radius: 4px;
}

.copyButton {
  height: 44px;
  flex-wrap: nowrap !important;
  width: 100%;
  padding-right: 32px;

  @media (max-width: 991.98px) {
    padding-right: 0;
  }
}

.copyButton input {
  font-size: 16px;
}
