.ancestry-message {
  background: white;
  padding: 1rem 2rem;
  color: $brand-primary-color;
  @media (max-width: 991.98px) {
    padding: 1rem;
    border: 2px solid $background-primary-color;
  }
  $ancestry-icon-width: 40px;
  .ancestry-message-wrapper {
    @include display-flex();
    @include align-items(center);
    .ancestry-message-icon-wrapper {
      width: calc(#{$ancestry-icon-width} + 1rem);
    }
    .ancestry-message-content-wrapper {
      width: calc(100% - #{$ancestry-icon-width} - 1rem);
      .h3 {
        text-transform: uppercase;
      }
    }
  }
  .ancestry-message-icon {
    background: $background-primary-color;
    height: $ancestry-icon-width;
    width: $ancestry-icon-width;
    text-align: center;
    border-radius: 50%;
    svg {
      width: 16px;
      height: 21px;
    }
  }
  .ancestry-message-subheading {
    font-style: italic;
    margin-bottom: 0px;
    @media (max-width: 767.98px) {
      margin-top: 3px;
      margin-bottom: 3px;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.ancestry-page-message {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  .ancestry-message {
    padding: 10px;
    .ancestry-message-icon-wrapper {
      width: calc(30px + 1rem) !important;
      .ancestry-message-icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        svg {
          width: 12px;
          height: 20px;
        }
      }
    }
    .h3 {
      font-size: 16px;
    }
    p {
      display: inline-block;
      padding-right: 10px;
    }
  }
}

.surveys-page-message {
  .ancestry-message {
    margin-bottom: 1rem;
    padding: 10px;
    .ancestry-message-icon-wrapper {
      width: calc(30px + 1rem) !important;
      .ancestry-message-icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        svg {
          width: 12px;
          height: 20px;
        }
      }
    }
  }
}

.header-custom {
  .ancestry-message {
    margin-bottom: 15px;
    margin-top: -15px;
    z-index: 2;
    position: relative;
    @media (min-width: 992px) {
      width: calc(100% - 120px + 30px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}
