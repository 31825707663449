.quick-polls {
  .h1 {
    font-size: 36px;
  }
  .quick-poll-button,
  .quick-poll-button:focus,
  .quick-poll-button:active {
    color: #005ca3 !important;
    background: #ebf0fa !important;
    border: none !important;
    &:hover {
      background: #005ca3 !important;
      color: white !important;
    }
    &:focus,
    &.focus {
      @include bootstrap-focus-shadow(!important);
      &:hover {
        padding: $spacer * 0.9 !important;
        border: solid $spacer * 0.1 white !important;
      }
    }
  }
  .quick-poll-question {
    p {
      font-size: 18px;
      font-weight: bold;
    }
    @media (min-width: 768px) {
      border-right: 1px solid $border-color;
    }
  }
  .quick-poll-answer {
    @media (min-width: 768px) {
      min-height: 310px;
    }
  }
  .quick-poll-wrapper {
    @media (max-width: 767.98px) {
      min-height: 390px;
    }
  }
  .quick-polls-skip {
    min-height: 40px;
    @media (min-width: 768px) {
      border-top: 1px solid $border-color;
    }
  }
  .answer-title {
    padding-right: 40px;
  }
  @media (max-width: 575.98px) {
    .quick-polls-card-wrapper {
      position: relative;
    }
    .has-forward:after,
    .has-back:before {
      content: "";
      height: 100%;
      width: 10px;
      position: absolute;
      top: 0;
      background: white;
    }
    .has-forward:after {
      right: -18px;
      border-radius: $spacer * 0.5 0 0 $spacer * 0.5;
    }
    .has-back:before {
      left: -18px;
      border-radius: 0 $spacer * 0.5 $spacer * 0.5 0;
    }
  }

  .quick-poll-answer-graph {
    position: relative;
    // overflow: hidden;
    .answer-title {
      position: relative;
      z-index: 2;
      color: $brand-primary-color;
    }
    .results-graph {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;
      .results-animation {
        // border-radius: $border-radius 0 0 $border-radius ;
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0.1;
        background: $brand-primary-color;
        animation: resultsBar 2s ease;
        border-radius: 0.5rem 0 0 0.5rem !important;
        @keyframes resultsBar {
          0% {
            left: 0%;
            right: 100%;
            width: 0%;
          }
          100% {
            left: 0%;
            right: 100%;
            width: 100%;
          }
        }
      }
    }
    .results-percentage {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $brand-primary-color;
      // opacity: 0.5;
      font-weight: bold;
      // font-size: 20px;
      z-index: 2;
    }
  }
  .selected {
    background: $background-primary-color;
    // color: white;
    .results-animation {
      background: $highlight-color-1 !important;
      opacity: 1 !important;
    }
  }
}
