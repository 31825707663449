@import "../../../styles/base/variables";
@import "../../../styles/base/typography";
@import "../../../styles/base/usability";

$header-color: #002e52;
$blue-background-color: #001d33;
$button-background: #dee8ef;
$white: #ffffff;
$link-hover-color: #ccc;
$read-more-hover-bg: #107ed4;
$blue-light-background-color: #e6eff6;

@mixin link-styles($bg-color: transparent, $padding: 12px 28px) {
  color: $white;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none !important;
  font-weight: bolder;
  padding: $padding;
  background: $bg-color;
}

@mixin background-image($url, $size: contain, $position: center) {
  background: {
    image: url($url);
    size: $size;
    repeat: no-repeat;
    position: $position;
  }
}

.ancestry-landing-page {
  max-width: 100% !important;

  :global {
    .paragraph-truncate {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .exit-icon {
      @include background-image("../../../assets/icons/Exit.svg");
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-left: 20px;
    }

    a.link {
      @include link-styles;

      &:hover {
        color: $link-hover-color !important;
      }

      &:focus {
        @include bootstrap-focus-shadow($white);
      }
    }

    a.read-more {
      @include link-styles($brand-primary-color);
      border-radius: $border-radius;
      margin-right: 1rem;
      width: fit-content;
      align-self: flex-start;

      &:hover {
        background: $read-more-hover-bg !important;
      }

      &:focus {
        padding: 11px 27px;
        border: 1px solid $button-background;
      }
    }

    .landing-hero-links {
      width: 100%;
    }

    @media (max-width: 767.98px) {
      .landing-hero-links {
        align-items: flex-start !important;
      }
      .landing-header-link {
        padding-left: 0 !important;
      }
    }

    .page-header {
      color: $header-color;
      padding: 10rem 0;

      h2 {
        color: inherit;
        font-size: 56px;
        font-weight: 700;
        margin-bottom: 1rem;
        overflow-wrap: break-word;
      }

      @media (max-width: 767.98px) {
        padding: 5.5rem 2.5rem 9rem;
        h2 {
          font-size: 32px;
        }
      }
    }

    .page-content-diversity {
      color: $white;
      background-color: $blue-background-color;
      @include background-image(
        "../../../assets/images/People.png",
        auto,
        right calc(min(calc(-828px + 34vw), 0px)) top -2px
      );

      @media (max-width: 767.98px) {
        background-position: top -263px right 0px;
        background-size: 745px auto;
        flex-direction: column;
      }

      @media (max-width: 575.98px) {
        background-position: top -92px right 0px;
        background-size: 520px auto;
      }

      .diversity-topic-img {
        height: 230px;
        @include background-image("../../../assets/images/People.png", cover);
      }

      .diversity-topic-content {
        padding: 137px 0 176px 0;

        @media (max-width: 767.98px) {
          padding: 300px 0 36px 0;
        }

        .diversity-topic {
          color: $white;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 8px;
          width: 48vw;
          max-width: 730px;

          h2,
          h3 {
            color: $white;
          }

          h2 {
            font-size: 36px;
            font-weight: 700;
          }

          ul {
            list-style: none;
            padding: 0;

            > li {
              color: $white;
              margin-bottom: 0.75rem;
            }
          }

          @media (max-width: 767.98px) {
            width: 100%;
          }
        }
      }
    }

    .page-content-articles {
      background: #f4f8fb;
      padding: 7.5rem 0;

      .article-card {
        border-radius: 20px;
        background-color: $blue-light-background-color;
      }

      .article-image {
        border-radius: 10px;
      }

      h1,
      h2 {
        font-size: 32px;
        font-weight: 700;
      }

      h1 {
        color: $brand-primary-color;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        color: $brand-primary-color-dark;
      }

      p {
        color: $brand-primary-color-dark;
      }

      @media (max-width: 575.98px) {
        h1 {
          font-size: 20px;
        }
      }

      @media (max-width: 767.98px) {
        padding: 2.5rem 2rem 9rem;
        h2 {
          font-size: 22px;
        }
      }

      @media (max-width: 991.98px) {
        padding: 2.5rem 2rem 9rem;
        h2 {
          font-size: 22px;
        }
      }
    }
  }
}
