.wrapper {
  background-color: #f0c1d9;
  background-image: url("../../assets/images/banner_invite_friends_b.svg");
  background-size: 353px;
  background-repeat: no-repeat;
  background-position: bottom 26px right 0px;
  padding: 40px 30px 360px;
  @media (min-width: 768px) {
    background-image: url("./svg/banner_invite_friends.svg");
    background-size: 550px;
  }
  @media (min-width: 992px) {
    background-image: url("./svg/banner_invite_friends.svg");
    background-size: 45%;
    background-position: right;
    padding: 40px 30px 40px;
  }
}

.title {
  font-size: 24px;
  @media (min-width: 992px) {
    width: 60%;
  }
}

.description {
  font-size: 18px;
  font-weight: bold;
  @media (min-width: 992px) {
    width: 60%;
  }
}

.link {
  @media (min-width: 992px) {
    width: 60%;
  }
}
