//edge fix
@supports (-ms-ime-align: auto) {
  .policies {
    .app-policy-list {
      margin-left: 3px !important;
      .row {
        top: -18px;
        position: relative;
      }
    }
  }
}

.policies {
  font-size: 12px;
  h2 {
    font-size: 14px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  .app-policy-list {
    padding-left: 15px !important;
    .row {
      position: relative;
    }
    .row > div {
      position: relative;
    }
  }
  .consent-footer {
    .btn-sm {
      padding: 8px 30px;
      @media (max-width: 575.98px) {
        &:last-of-type {
          margin-top: 0 !important;
        }
      }
    }
  }
}
