.footer-white-bg {
  @media (max-width: 575.98px) {
    background-color: #fff !important;
    .unauth-footer {
      color: $brand-primary-color !important;
      a {
        color: $brand-primary-color !important;
      }
    }
  }
}
