.header-logo {
  width: 190px;
  margin-top: 0px;
  img {
    width: 100%;
  }
  @media (max-width: 991.98px) {
    width: 180px;
    img {
      width: 100%;
    }
  }
}

header {
  background: $brand-primary-color;
  color: #fff;
  border-bottom: $spacer solid white;
  position: relative;
  @media (min-width: 768px) {
    min-height: 200px;
  }
  @media (max-width: 767.98px) {
    border-bottom: $spacer solid white;
    overflow: hidden;
    min-height: 200px;
  }
  &:before {
    content: "";
    display: block;
    background: transparent;
    background: radial-gradient(
      ellipse at bottom,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(0, 92, 163, 0) 60%
    );
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    width: 80vw;
    height: 100px;
    border-radius: 50% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .header-image-right {
    display: block;
    position: absolute;
    right: 0;
    max-height: 220px;
    bottom: 0;
    z-index: 0;
    img {
      max-height: 220px;
    }
  }
  .header-image-desktop {
    display: none;
    @media (min-width: 768px) {
      display: block;
      position: absolute;
      right: 0;
      max-height: 180px;
      bottom: 0;
      img {
        max-height: 180px;
      }
    }
  }
  .header-character-desktop {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      position: absolute;
      left: -180px;
      height: 155px;
      bottom: 0px;
      overflow: hidden;
      svg {
        width: 550px;
        height: 600px;
      }
    }
  }
  .header-character-mobile {
    display: none;
    @media (max-width: 767.98px) {
      display: block;
      position: absolute;
      right: -340px;
      height: 400px;
      top: -35px;
      overflow: hidden;
      svg {
        width: 540px;
        height: 540px;
      }
    }
  }
  .header-donut-desktop {
    display: block;
    width: 500px;
    height: 500px;
    top: -260px;
    right: -155px;
    position: absolute;
    z-index: 0;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  .header-message {
    position: relative;
    z-index: 1;
  }
  .header-hello {
    @media (max-width: 767.98px) {
      padding: $spacer 0 $spacer * 3 0;
    }
  }
  .header-custom {
    color: white;
    padding-top: $spacer;
    letter-spacing: 1px;
    padding-bottom: 0;
    @media (max-width: 767.98px) {
      padding-top: 0;
    }
    h1 {
      color: white;
      font-weight: normal;
      font-size: 40px;
      @media (max-width: 767.98px) {
        font-size: 28px;
      }
    }
  }
  .header-custom-profile {
    @media (max-width: 767.98px) {
      padding-top: $spacer;
      padding-bottom: 0;
    }
  }
  @media (max-width: 991.98px) {
    .header-signout {
      // padding: 0;
      .col-6 {
        padding: 0;
      }
    }
  }
  @media (max-width: 767.98px) {
    .header-signout .name {
      display: none;
    }
  }
}

.header-dashboard {
  @media (max-width: 767.98px) {
    padding-bottom: $spacer * 3 !important;
  }
}

.header-survey-mobile {
  @media (max-width: 767.98px) {
    border-bottom: none;
    overflow: hidden;
    min-height: 0px;
  }
}

.nav-name {
  display: inline-block;
  padding-left: 3rem;
  &.unauth-header {
    padding-left: 0;
    color: white;
  }
}
.nav-language {
  display: inline-block;
  .header-languages {
    margin-top: 0;
  }
}

.custom-header-mobile {
  font-size: 13px;
  @media (min-width: 1200px) {
    display: none;
  }
}

.header-profile {
  .header-character-mobile {
    @media (max-width: 767.98px) {
      height: 220px;
      bottom: 0;
    }
  }
}
