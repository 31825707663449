$landing-space-s: 1rem;
$landing-space-m: 2rem;
$landing-space-l: 3rem;
$landing-space-xl: 4rem;

$landing-section-space: 6rem;

$landing-mid-body-bottom-background: #dee8ef;
$landing-mid-body-gradient: linear-gradient(
  to bottom,
  #ffffff 0%,
  $landing-mid-body-bottom-background 100%
);

$landing-text-max-width: 800px;

$landing-color-pink: #ffdbed;
.unauth-landing {
  background: white;
}

.content-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  padding-bottom: 20px;
  text-align: center;
}
.content-subtitle {
  font-size: 68px;
  font-weight: 800;
  line-height: 70px;
  padding-bottom: 24px;
  text-align: center;
}
.content-description {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
}

.cards-content-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  padding-bottom: 8px;
}

.landing-page {
  width: 100%;
  .landing-header-wrapper {
    background-color: $rutgers-brand-primary-color;
    padding: 3px 0 60px;
    background-image: url("../../assets/images/DNA-cropped.svg");
    background-repeat: no-repeat;
    background-size: 620px 853px;
    background-position: 80% top;

    @media (min-width: 1200px) {
      background-position: 78% top;
    }

    @media (max-width: 575.98px) {
      background-size: 725px;
      background-position: -70px top;
      padding: 6rem 0 3rem;
    }

    .landing-header-content {
      width: 70%;
      margin: 0 auto;
      position: relative;
      z-index: 5;
      max-width: 1095px;

      .header-content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .header-landing-content {
          max-width: 60%;
          color: black;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 60px 0 40px;

          @media (max-width: 991.98px) {
            max-width: 100%;
            padding: 60px 0 40px;
          }

          @media (max-width: 575.98px) {
            padding: 0;
          }

          h1 {
            font-size: 32px;
            font-weight: 400;
            color: black;
          }

          p {
            max-width: $landing-text-max-width;
          }
          &:before {
            content: "";
            position: absolute;
            width: calc(100% + 180px);
            height: 100%;
            background-size: 180px, 180px;
            background-repeat: no-repeat;
            background-position:
              5em 4em,
              3em 12em;
            z-index: -1;
            background-image: url("../../assets/images/badge_all_genders_welcome.svg"),
              url("../../assets/images/badge_expected_time_required.svg");
            @media (max-width: 991.98px) {
              display: none;
            }
          }
        }
      }

      .landing-cards-wrapper {
        padding: 40px;
        background-color: white;
        border-radius: 20px;
        color: black;
        .landing-cards-title {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .landing-cards-content {
          display: inline-flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: -16px;
          .card-border {
            padding: 16px;
            display: flex;
          }
          .landing-card {
            padding: 24px 8px 42px;
            background-color: white;
            border-radius: 32px;
            width: 100%;
            .card-border {
              background: #000;
              padding: 12px;
            }
            svg {
              width: 100px;
              height: 100px;
            }

            .card-description {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              gap: 12px;
              text-align: left;

              h3 {
                color: #000;
                font-size: 19px;
              }
            }
          }
        }

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    a.landing-cards-banner {
      border-radius: 20px;
      align-items: center;
      background-color: #001d33;
      display: flex;
      justify-content: center;
      color: white;
      gap: 27px;
      padding: 19px;
      text-decoration: none;
      &:hover {
        background-color: #013963 !important;
      }
    }

    .card-banner-right-text {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
    }

    .card-banner-left-text {
      font-size: 20px;
      line-height: 26px;
      text-align: right;
      color: $landing-color-pink;
      font-weight: 700;
    }
  }

  a.landing-card-link {
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: 700;
    &:hover {
      color: #ccc !important;
    }
    &:focus {
      @include bootstrap-focus-shadow(white);
    }
  }

  a.landing-card-banner-link {
    color: #013963;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: 700;
    &:hover {
      color: #013963 !important;
    }
    &:focus {
      @include bootstrap-focus-shadow(#013963);
    }
  }

  .landing-sm-cards-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 32px 0;
    gap: 36px;
    color: black;
    .landing-card {
      background-color: white;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        width: 100px;
        height: 100px;
      }
      .card-description {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 12px;
        text-align: center;
        padding: 0;
        h3 {
          color: #000;
        }
      }
    }
    .landing-sm-cards-banner {
      border-radius: 20px;
      padding: 19px 0;
      align-items: center;
      background-color: #001d33;
      display: flex;
      flex-direction: column;
      color: white;
      width: 100%;

      .card-banner-right-text & .card-banner-left-text {
        font-size: 16px;
      }
    }
  }

  .landing-topic-wrapper {
    color: #ffffff;

    background-color: $brand-primary-color;
    background-image: url("../../assets/images/People.png");
    background-position: right calc(min(calc(-828px + 34vw), 0px)) top -2px;
    background-repeat: no-repeat;

    @media (max-width: 767.98px) {
      background-position: top -263px right 0px;
      background-size: 745px auto;
      flex-direction: column;
    }

    @media (max-width: 575.98px) {
      background-position: top -92px right 0px;
      background-size: 520px auto;
      flex-direction: column;
    }

    .landing-topic-img {
      height: 230px;
      background: center / cover no-repeat url("../../assets/images/People.png");
    }

    .landing-topic-content {
      width: 70%;
      position: relative;
      margin: 0 auto;
      padding: 137px 0 176px 0;
      @media (max-width: 767.98px) {
        padding: 300px 0 36px 0;
      }

      .landing-topic {
        color: white;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;
        width: 48vw;
        max-width: 730px; // 2/3 of landing-content max-width: 1095px;
        h3 {
          color: white;
        }
        h2 {
          color: #ffffff;
          font-size: 32px;
          font-weight: 700;
        }
        ul {
          list-style: none;
          padding: 0;
          > li {
            color: #ffffff;
            margin-bottom: 0.75rem;
          }
        }

        @media (max-width: 767.98px) {
          width: 100%;
        }
      }
    }
  }

  .landing-data-protection-wrapper {
    color: #ffffff;
    background: #001d33;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0;

    .landing-data-protection-content {
      // padding: 179px 0 39px;
      padding: 50px 0 39px;
      background:
        center right/ 38% 50% no-repeat url("../../assets/images/security.svg"),
        top right / 6% 58% no-repeat
          url("../../assets/images/security-circles.svg");

      @media (max-width: 1199.98px) {
        padding: 179px 0 39px;
        background:
          top left -10px/ 25% no-repeat url("../../assets/images/security.svg"),
          top left 165px / 8% 10% no-repeat
            url("../../assets/images/security-circles.svg");
      }

      @media (max-width: 991.98px) {
        background:
          top left -10px/ 25% no-repeat url("../../assets/images/security.svg"),
          top left 112px / 15% 8% no-repeat
            url("../../assets/images/security-circles.svg");
      }

      @media (max-width: 575.98px) {
        background:
          top left -27px/ 40% no-repeat url("../../assets/images/security.svg"),
          top left 112px / 13% 8% no-repeat
            url("../../assets/images/security-circles.svg");
      }

      @media (max-width: 767.98px) {
        background:
          top left -20px/ 55% no-repeat url("../../assets/images/security.svg"),
          top left 112px / 50% 8% no-repeat
            url("../../assets/images/security-circles.svg");
      }

      .landing-data-protection {
        max-width: $landing-text-max-width;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-right: 200px;

        @media (max-width: 1199.98px) {
          padding-right: 300px;
          // padding-top: 189px;
        }

        @media (max-width: 991.98px) {
          padding-right: 0px;
        }

        @media (max-width: 767.98px) {
          padding-top: 100px;
        }

        @media (max-width: 575.98px) {
          padding-top: 0px;
        }

        h2 {
          color: #ffffff;
          font-size: 32px;
        }
        h4 {
          color: #ffffff;
          font-size: 18px;
        }
        ul {
          list-style: none;
          padding: 0;
          > li {
            color: #ffffff;
            margin-bottom: 0.75rem;
          }
        }

        .landing-hero-links {
          margin-top: 0;
          margin-left: -28px;
        }
      }
    }

    @media (max-width: 767.98px) {
      flex-direction: column;
    }
  }
}

.landing-wrapper {
  margin-top: -2px !important;
  color: #000;
}

.landing-content {
  margin: 0 auto;
  width: 70%;
  max-width: 1095px;
  position: relative;
  z-index: 5;
}

.landing-content-banner {
  margin: 0 auto;
  width: 70%;
  position: relative;
  z-index: 5;

  @media (max-width: 767.98px) {
    width: 70%;
    padding-top: 60px;
  }
}

.icon-arrow-right {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -3px;
  margin-left: $landing-space-m;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: white;
    position: absolute;
    transform-origin: right center;
  }

  &::before {
    transform: translate(0, 1px) rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.iconArrowRight {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
  background-color: white;
  width: 10px;
  height: 2px;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: white;
    position: absolute;
    transform-origin: right center;
  }

  &::before {
    transform: translate(0, 1px) rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.landing-body-wrapper {
  color: #000;

  h3 {
    color: #000;
    margin-bottom: $landing-space-s;

    @media (max-width: 767.98px) {
      margin-bottom: 0.5rem;
    }
  }
  p {
    @media (max-width: 767.98px) {
      margin-bottom: $landing-space-m;
    }
  }
  @media (max-width: 767.98px) {
    text-align: left;
  }
}

.icon-box {
  width: 82px;
  height: 82px;
  margin-bottom: 1rem;
  border: 1px solid $brand-primary-color;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767.98px) {
    margin: 0 auto 0.5rem;
  }
}

.landing-hero-wrapper {
  width: 100%;
  max-width: 100%;
  color: white;
  background-color: $brand-primary-color;
  display: flex;
  flex-direction: row;

  h2,
  h3 {
    color: white;
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
    font-weight: 300;

    > small {
      display: inline-block;
      margin-right: 8px;
    }
  }
  ul {
    list-style: none;
    padding: 0;

    > li {
      margin-bottom: 0.75rem;
    }
  }

  @media (max-width: 767.98px) {
    text-align: center;
    flex-direction: column-reverse;
  }
  .landing-hero-topic {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .landing_topic {
    padding: 4rem;
    @media (max-width: 1199.98px) {
      padding: 2rem 0 0 2rem;
      .landing-hero-links {
        display: none;
      }
    }

    @media (max-width: 1199.98px) {
      padding: 2rem 0 0 2rem;
      .landing-hero-links {
        display: none;
      }
    }
  }
}

.landing-page-character {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-tick {
  width: 16px;
  display: inline-block;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 12px;
}

.landing-hero-links {
  // margin-top: $landing-space-m;

  a.landing-hero-button {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;

    &:hover {
      background: #107ed4 !important;
    }

    &:focus {
      padding: 11px 27px;
      border: 1px solid $landing-mid-body-bottom-background;
    }
  }
  .landing-hero-button {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;

    &:hover {
      background: #107ed4 !important;
    }

    &:focus {
      padding: 11px 27px;
      border: 1px solid $landing-mid-body-bottom-background;
    }
  }

  a.landing-sm-banner-hero-button {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;
    width: 100%;
    text-align: center;
    &:hover {
      background: #107ed4 !important;
    }

    &:focus {
      padding: 11px 27px;
      border: 1px solid $landing-mid-body-bottom-background;
    }
  }

  a.landing-hero-link {
    color: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: bolder;

    &:hover {
      color: #107ed4 !important;
    }
  }

  a.landing-hero-button {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;

    &:hover {
      background: #107ed4 !important;
    }

    &:focus {
      padding: 11px 27px;
      border: 1px solid $landing-mid-body-bottom-background;
    }
  }

  .landing-hero-button {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;

    &:hover {
      background: #107ed4 !important;
    }

    &:focus {
      padding: 11px 27px;
      border: 1px solid $landing-mid-body-bottom-background;
    }
  }

  a.landing-header-button {
    background: white;
    color: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: $landing-space-m;

    &:hover {
      background: #eee !important;
    }
    &:focus {
      padding: 11px 27px;
      border: 1px solid $brand-primary-color;
      @include bootstrap-focus-shadow(white);
    }
  }

  a.landing-header-link {
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: bolder;
    padding: 12px 28px;
    &:hover {
      color: #ccc !important;
    }
    &:focus {
      @include bootstrap-focus-shadow(white);
    }
  }

  @media (max-width: 767.98px) {
    .landing-header-link {
      align-items: flex-start !important;
    }
    .landing-header-link {
      padding-left: 0 !important;
    }
  }

  a.landing-data-protection-link {
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: bolder;
    padding: 12px 28px;
    &:hover {
      color: #ccc !important;
    }
    &:focus {
      @include bootstrap-focus-shadow(white);
      // margin: 2px;
    }
  }
}

.landing-video-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  border: 1px solid $brand-primary-color;
  border-radius: 50%;
  margin-left: 0.5rem;

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: relative;
    left: 1px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid $brand-primary-color;
  }
}

.landing-about-wrapper {
  padding: $landing-space-m 0;
  color: #000;

  h2,
  h3,
  h4 {
    color: #000;
    font-weight: normal !important;
  }
  h2 {
    margin-bottom: $landing-space-s;
  }
  h3 {
    margin-bottom: $landing-space-m;
  }
  h4 {
    margin-bottom: $landing-space-l;
  }

  @media (max-width: 575.98px) {
    .col-about:first-child {
      margin-bottom: $landing-space-s;
    }
    h3,
    h4 {
      margin-bottom: $landing-space-s;
    }
  }
}

.landing-referral-banner-wrapper {
  padding: 60px 0 80px;
  color: #000;

  &::before {
    content: "";
    background-image: url("../../assets/images/subtract.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 13rem;
    transform: rotate(180deg);
    // z-index: 6;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .landing-content-banner {
    h3 {
      color: $brand-primary-color;
      font-weight: 700;
      margin-bottom: 29px;
      font-size: 32px;
      line-height: 40px;
    }
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .referral-buttons-wrapper {
    gap: 1rem;
  }

  .referral-links-wrapper {
    display: flex;
    gap: 1rem;
    width: 70%;

    @media (max-width: 991.98px) {
      width: 100%;
      padding: 0 0 80px;
    }
  }

  @media (max-width: 767.98px) {
    padding: 0 0 25px;
    background-color: #f0c1d9;
    .landing-content-banner {
      h3 {
        font-size: 18px;
        margin-right: 0;
        font-weight: bold;
      }
    }
    .referral-buttons-wrapper {
      gap: 1rem;
      .primary-invert {
        display: none;
      }

      .btn-primary {
        background-color: #fff !important;
        border: #fff !important;
        color: $brand-primary-color !important;
        &:hover {
          background-color: $brand-primary-color !important;
          border: $brand-primary-color !important;
          color: #fff !important;
        }
      }
    }
  }
}

.referral-banner-wrapper {
  background-image: url("../../assets/images/banner_invite_friends_b.svg");
  background-repeat: no-repeat;
  // background-size: 85%;
  background-position: right;
  height: 337px;
  @media (min-width: 768px) {
    display: none;
  }
}

.landing-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $landing-space-m $landing-space-m;
  background-color: $brand-primary-color;
  //   margin-top: 6rem;
  .landing-content {
    display: flex;
    color: white !important;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-position:
      130% 197%,
      -30% -103%;
    background-repeat: no-repeat;

    @media (max-width: 767.98px) {
      background-position:
        -40% -490%,
        212% 550%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  input,
  input::placeholder {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    font-size: 14px !important;
    color: $brand-primary-color !important;
    font-weight: lighter !important;
    flex: 1;
  }

  @media (max-width: 767.98px) {
    .col-sm,
    .landing-footer-text {
      text-align: center !important;
    }
    .landing-footer-text {
      margin-bottom: 1rem;
    }
  }
}

.landing-footer-input-wrapper {
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: $border-radius;
  white-space: nowrap;
  display: flex;
  width: 100%;

  @media (max-width: 767.98px) {
    width: 100%;
  }
}

.landing-footer-input-icon {
  appearance: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: transparent;
  border-right: none;
  border-top: none;
  border-bottom: none;
  height: 80%;
  width: 50px;
  vertical-align: middle;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.886);
  border-radius: 0;

  > svg {
    height: 60%;
    width: auto;
  }

  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}

.landing-footer-input-btn {
  appearance: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: transparent;
  border-right: none;
  border-top: none;
  border-bottom: none;
  // width: 100px;
  vertical-align: middle;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.886);
  border-radius: 0;
  color: white;

  > svg {
    height: 60%;
    width: auto;
  }

  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}
.landing-footer-text {
  display: inline-block;
}

.unauth-header-landing-menu {
  position: absolute;
  z-index: 8;
  top: 20px;
  right: 25px;
  transition: 0.25s ease;

  @media (max-width: 767.98px) {
    background: $brand-primary-color;
    top: 0;
    right: 0;
    min-height: 100vh;
    z-index: 8;
    width: 100%;
    padding: 0 $landing-space-s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &._open {
      visibility: visible;
      width: 100vw;
    }
    &._closed {
      position: fixed;
      visibility: hidden;
      transform: translateX(100%);
    }
  }
}
.unauth-header.logged {
  .landing-header-menu-links,
  .landing-header-menu-buttons,
  .landing-header-menu-language {
    vertical-align: top;
  }
}

.landing-header-menu-links,
.landing-header-menu-buttons,
.landing-header-menu-language {
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 767.98px) {
    display: block;
    margin-right: 0 !important;
    width: 100%;
  }
}

.landing-header-menu-language {
  margin-right: $landing-space-l;
  @media (max-width: 767.98px) {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: $landing-space-m;
    .dropdown-toggle,
    .header-languages .dropdown-item {
      font-size: 18px !important;
    }
  }
}

.landing-header-menu-links {
  margin-right: $landing-space-l;
}

.landing-header-menu-links > a,
.landing-header-menu-buttons > a {
  text-decoration: none;
  display: inline-block;
  padding: 0 10px;

  @media (max-width: 767.98px) {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: $landing-space-m;
    font-size: 18px;
  }
}

.landing-header-menu-links > a {
  color: white;
  font-weight: bolder;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}

.landing-header-menu-buttons > a {
  color: white;
  background: transparent;
  border: 1px solid white;
  font-weight: 300;
  border-radius: $border-radius;
  padding: 4px 10px;
  margin-right: $landing-space-s;

  @media (max-width: 767.98px) {
    padding: $landing-space-s;
    margin-bottom: $landing-space-s;
  }

  &:focus {
    padding: 3px 9px;
    @include bootstrap-focus-shadow(white);
  }
}

.landing-header-menu-buttons > a:hover,
.landing-header-menu-buttons > a._highlighted {
  background: white !important;
  color: $brand-primary-color !important;

  &:focus {
    padding: 3px 9px;
    border: 1px solid $brand-primary-color;
    @include bootstrap-focus-shadow(white);
  }
}

.landing-header-menu-buttons > a._highlighted:hover {
  background: rgba(255, 255, 255, 0.85) !important;
}

.landing-header-menu-hamburger {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 9;
  display: none;

  > .hamburger-strip {
    width: 32px;
    height: 4px;
    background: white;
    display: block;
    position: absolute;
    border-radius: 3px;
    transition: 0.25s ease;
    transform-origin: left center;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 14px;
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }

  &._open > .hamburger-strip:nth-child(1) {
    transform: translateY(2.5px) rotate(45deg);
  }
  &._open > .hamburger-strip:nth-child(2) {
    opacity: 0;
  }
  &._open > .hamburger-strip:nth-child(3) {
    transform: translateY(-2.5px) rotate(-45deg);
  }

  @media (max-width: 767.98px) {
    display: block;
  }
}

.landing-wrapper .unauth-header-logo {
  position: relative;
  z-index: 9;
}

.unauth-header-logo {
  padding-top: 5px;
}

.unauth-header {
  padding: 1rem;
}
.unauth-referral-header {
  padding: 1rem;
  background-color: $brand-primary-color;
}

.landing-ancestry-banner-wrapper {
  background: #f4f8fb;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .landing-ancestry-banner-content {
    padding: 50px 0 39px;
    .landing-ancestry-banner {
      .landing-ancestry-banner-button {
        color: white;
        background: $brand-primary-color;
        display: inline-block;
        vertical-align: middle;
        padding: 12px 28px !important;
        border-radius: $border-radius;
        text-decoration: none !important;
        font-weight: bolder;
        margin-right: 1rem;
        width: fit-content;
        align-self: flex-start;
        overflow-wrap: break-word;
        &:hover {
          background: #107ed4 !important;
        }

        &:focus {
          padding: 11px 27px;
          border: 1px solid $landing-mid-body-bottom-background;
        }
      }
      h1 {
        color: $brand-primary-color;
        max-width: 15em;
        line-height: 1.2;
        font-size: 2rem;
        font-weight: bolder;
        margin-bottom: 20px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        @media (max-width: 767.98px) {
          font-size: 1.5rem;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        > li {
          color: #000;
          margin-bottom: 0.75rem;
          display: flex;
          align-items: center;
        }
        .landing-tick {
          flex-shrink: 0;
          margin-right: 10px;
        }
        .landing-ancestry-list-text-wrapper {
          width: 100%;
          @media (min-width: 768px) {
            width: 50%;
          }

          span {
            display: inline-block;
            width: 100%;
            vertical-align: middle;
          }
        }
      }
      .landing-video-content {
        @media (max-width: 767.98px) {
          position: absolute;
          width: 100vw;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
