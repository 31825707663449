.profile {
  position: relative;
  .profile-desktop-only {
    display: block;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .profile-mobile-only {
    display: block;
    @media (min-width: 992px) {
      display: none;
    }
  }
  .profile-personal-info {
    .profile-field {
      .profile-field-label {
        color: $brand-primary-color;
        font-weight: bold;
        text-transform: uppercase;
      }
      .profile-field-value {
        position: relative;
        // color: $form-input-color;
        // background: $background-primary-color;
        // font-weight: bold;
        font-size: 18px;
        // border-bottom: 1px solid $border-color;
        margin-top: $spacer * 0.25;
        padding-bottom: $spacer * 0.75;
        margin-bottom: $spacer;
        &.can-edit {
          padding-right: 54px !important;
        }
        @media (max-width: 767.98px) {
          font-size: 14px;
        }
      }
      &:last-of-type {
        .profile-field-value {
          margin-bottom: 0;
        }
      }
      .btn {
        position: absolute;
        right: $spacer;
        top: 50%;
        transform: translateY(-50%);
        color: $brand-tertiary-color;
        &:hover {
          color: $brand-primary-color;
        }
      }
    }
  }
  .right-section {
    @media (min-width: 992px) {
      position: relative;
    }
  }
  .profile-account-options {
    text-align: center;
    @media (min-width: 992px) {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
    .btn-logout {
      color: $brand-primary-color !important;
      display: block;
      width: 100%;
      &:hover,
      &:focus,
      &:active {
        color: white !important;
        background-color: $brand-primary-color !important;
      }
    }
  }
  .security {
    .btn-secondary {
      background-color: #fff !important;

      color: $brand-primary-color !important;
      &:hover {
        background: #000 !important;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .profile-notifications {
    .profile-notification-box {
      @media (min-width: 992px) {
        padding-bottom: 90px;
      }
      .profile-notification-col:last-of-type {
        height: 22px;
      }
      label {
        color: $copy-primary-color;
      }
      @media (max-width: 991.98px) {
        background-color: white;
        padding: $spacer $spacer * 2 $spacer * 3 $spacer * 2;
        border-radius: $border-radius;
        margin-top: $spacer;
        .row {
          .profile-notification-col {
            padding: 0;
          }
        }
      }
    }
    .checkbox-toggle + .checkbox-toggle-label {
      @media (min-width: 992px) {
        background-color: white;
      }
    }
  }
  .center-section,
  .right-section {
    .mobile-only {
      display: block !important;
      @media (min-width: 992px) {
        display: none !important;
      }
    }
    .desktop-only {
      display: block !important;
      @media (max-width: 991.98px) {
        display: none !important;
      }
    }
  }
}

.legal-profile {
  .legal-wrapper {
    .legal-accordian {
      background: transparent;
      color: black;
      text-align: left;
      border: none;
      position: relative;
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      padding: 18px 12px;
      &:hover {
        background: transparent !important;
        color: $brand-primary-color;
      }
      @media (max-width: 991.98px) {
        padding-left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        right: 8px;
        width: 10px;
        height: 10px;
        top: 50%;
        border: solid $brand-primary-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        transform: rotate(-45deg) translateY(-50%);
      }
    }
    .legal-accordian-link {
      .icon-wrapper {
        width: 14px;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        svg {
          fill: $brand-primary-color;
        }
      }
      &:after {
        display: none;
      }
    }
    .legal-accordian-wrapper {
      border-bottom: 1px solid $border-color;
      &.expanded {
        .legal-accordian:after {
          right: 16px;
          transform: rotate(45deg) translateY(-100%);
        }
      }
      a {
        width: 100%;
        display: block;
      }
    }
  }
}

// this legal is being used outside of profile in 404, about-us, contact-us, privacy_policy and terms_and_conditions
.legal {
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: $spacer * 0.5;
  }
  h2 {
    font-size: 19px;
    margin-top: $spacer;
    margin-bottom: $spacer * 0.5;
    font-weight: bold;
  }
  .legal-wrapper {
    .legal-accordian {
      background: transparent;
      color: black;
      text-align: left;
      border: none;
      position: relative;
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      padding: 18px 12px;
      &:hover {
        background: transparent !important;
        color: $brand-primary-color;
      }
      @media (max-width: 991.98px) {
        padding-left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        right: 8px;
        width: 10px;
        height: 10px;
        top: 50%;
        border: solid $brand-primary-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        transform: rotate(-45deg) translateY(-50%);
      }
    }
    .legal-accordian-link {
      .icon-wrapper {
        width: 14px;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        svg {
          fill: $brand-primary-color;
        }
      }
      &:after {
        display: none;
      }
    }
    .legal-accordian-wrapper {
      border-bottom: 1px solid $border-color;
      &.expanded {
        .legal-accordian:after {
          right: 16px;
          transform: rotate(45deg) translateY(-100%);
        }
      }
      a {
        width: 100%;
        display: block;
      }
    }
  }
}

.security .security-wrapper,
.accordion-wrapper {
  border-bottom: 1px solid $border-color;
  border-radius: 0;
  .accordion-button {
    background: transparent;
    color: black;
    text-align: left;
    border: none;
    position: relative;
    margin: 0;
    font-size: 14px;
    padding: 18px 12px;
    &:hover {
      background: transparent !important;
      color: $brand-primary-color;
    }
    @media (max-width: 991.98px) {
      padding-left: 0;
    }
    &:after {
      content: "";
      position: absolute;
      right: 8px;
      width: 10px;
      height: 10px;
      top: 50%;
      border: solid $brand-primary-color;
      border-width: 0 2px 2px 0;
      display: inline-block;
      transform: rotate(-45deg) translateY(-50%);
    }
    &.active {
      &:after {
        right: 12px;
        transform: rotate(45deg) translateY(-50%);
      }
    }
  }
  .accordion-container {
    position: relative;
    display: none;
    &.active {
      display: block;
    }
  }
  .checkbox-toggle + .checkbox-toggle-label {
    background-color: white;
  }
  .desktop-right-align {
    @media (min-width: 768px) {
      text-align: right;
    }
  }
}

.profile-cta {
  min-width: 100%;
  @media (min-width: 768px) {
    min-width: 180px;
  }
}

.modal-content {
  .modall-body-email,
  .modal-body-ph_mobile {
    height: 195px;
    position: relative;
    button {
      position: absolute;
      bottom: 1rem;
      width: calc(100% - 2rem);
    }
  }
}

.unauth-page {
  .legal {
    h1 {
      font-size: 20px;
    }
  }
}
