.six-digit-code {
  .otp-code {
    text-align: center;
    display: inline-block;
    margin-right: 5px;
    width: calc(16.55% - 4px);
    border: 1px solid $brand-primary-color !important;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
