.dashboard-survey-header {
  position: relative;
  background: $brand-primary-color;
  color: white;
  min-height: 172px;
  background-image: url(../../assets/images/banner-background.svg);
  background-size: cover;

  h3 {
    color: white;
    font-size: 28px;
  }
}

.dashboard-survey-header-content {
  display: none;
  @media (min-width: 768px) {
    width: 58%;
    display: block;
  }
}

.dashboard-survey-iframe {
  padding: 0;

  @media (min-width: 576px) {
    padding: 1.5rem;
  }
}

.dashboard-survey-header-close {
  float: right;
  padding: 0.9rem;
  margin-top: -1.5rem;
  margin-right: -3rem;
  background: transparent;
  border: none;
}

.dashboard-survey-footer {
  background: $brand-primary-color;
  height: 30px;
}
