.internet-explorer {
  @media (min-width: 768px) {
    .action-card-wrapper {
      display: block !important;
    }
    .action-cards {
      .action-card {
        height: 260px;
      }
    }
    .survey {
      .action-card {
        height: 300px;
      }
      .action-card-wrapper {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          margin-top: 50px;
        }
      }
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    padding: 0 12px !important;
    height: 38px !important;
  }
  .app-policy-list li .row {
    margin-top: -18px;
  }
}

.safari {
  .policies {
    .app-policy-list {
      margin-left: 3px !important;
    }
  }
}
