@import "../../../styles/base/variables.scss";

.questionAnswerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 16px 18px 20px;

  position: static;
  height: 60px;
  left: 0px;
  top: 120px;

  /* Secondary/20 */

  background: $highlight-color-1;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0px;

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    color: $copy-primary-color;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 1;
  }

  .removeButton {
    order: 3;
    padding: 0px;
    border-radius: 50%;
    border: none;
  }
}
