@import "./bootstrap-variable-overrides.scss";

@mixin bootstrap-focus-shadow($color: $input-btn-focus-color, $important: "") {
  @if type-of($color) == color {
    box-shadow: 0 0 0 0.2rem rgba($color, 0.8) #{$important};
  }
}

@mixin bootstrap-focus-shadow-light($important: "") {
  @include bootstrap-focus-shadow($input-btn-focus-color, $important);
}
